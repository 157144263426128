<template>
  <div class="fu_box">
    <div class="protocol">
    <div style="margin:0 auto;width:950px">
      <div style="text-align: left; left: 0px; width: 950px; overflow: hidden;  line-height: inherit; top: 0px;"><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>用户协议
      </span></p><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>版本生效日期：2021年6月24日
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>版本更新日期：2021年6月24日
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>一、服务条款的确认及接受
      </span></p><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>1、新疆煤炭交易中心官网（以下称“本网站”）各项电子服务的所有权和运作权归属于“新疆煤炭交易中心”所有，本网站提供的服务将完全按照其发布的服务条款和操作规则严格执行。您确认所有服务条款并完成注册程序时，本协议在您与本网站间成立并发生法律效力，同时您成为本网站正式用户。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>2、根据国家法律法规变化及本网站运营需要，新疆煤炭交易中心有权对本协议条款及相关规则不时地进行修改，修改后的内容一旦以任何形式公布在本网站上即生效，并取代此前相关内容，您应随时关注本网站公告、提示信息及协议、规则等相关内容的变动。您知悉并确认，如您不同意更新后的内容，应立即停止使用本网站；如您继续使用本网站，即视为知悉变动内容并同意接受。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>3、您点击“同意”按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>4、您注册成为本网站用户后，将产生用户名和密码等账号信息，您可以根据网站规定改变密码。您应谨慎合理的保存、使用用户名和密码。若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通知我们，如发现违法犯罪行为请及时公安机关报案。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>5、您应按照本网站发布的各项服务规则的要求进行操作并提交所需各项信息和材料。您应保证上述信息、材料真实、合法有效。注册资料如有变动的，必须及时更新。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>二、服务须知
      </span></p><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>1、使用本网站，您必须：
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（1）自行配备上网的所需设备，包括个人手机、平板电脑、调制解调器、路由器等；
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（2）自行负担个人上网所支付的与此服务有关的电话费用、网络费用等；
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>2、基于本网站所提供的网络服务的重要性，您确认并同意：
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（1）提供的注册资料真实、准确、完整、合法有效，注册资料如有变动的，应及时更新；
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（2）如果您提供的注册资料不合法、不真实、不准确、不详尽的，您需承担因此引起的相应责任及后果，并且新疆煤炭交易中心保留终止您使用本网站各项服务的权利。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>三、用户个人信息保护及授权
      </span></p><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>1、您知悉并同意，为方便您使用本网站相关服务，本网站将存储您在使用时的必要信息，包括但不限于您的真实姓名或名称、性别、出生日期、邮寄地址、联系方式等。除法律法规规定或《隐私政策》另有约定的情形外，未经您的许可新疆煤炭交易中心不会向第三方公开、透露您的个人信息。新疆煤炭交易中心对相关信息采取专业加密存储与传输方式，利用合理措施保障用户个人信息的安全。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>2、您知悉并确认，您在注册帐号或使用本网站的过程中，需要提供真实的身份信息，新疆煤炭交易中心将根据国家法律法规相关要求，进行真实身份信息认证，认证成功后该账号将归属实名主体，实名主体有权变更账号相关信息。若您提供的信息不真实、不完整，则无法使用本网站或在使用过程中受到限制，同时，由此产生的不利后果，由您自行承担。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>3、您在使用本网站某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”），您在使用该项服务前请阅读并同意相关的单独协议；您使用前述特定服务，即视为您已阅读并同意接受相关单独协议。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>4、您充分理解并同意：
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（1）为配合行政监管机关、司法机关执行工作，在法律规定范围内中国班版权保护中心有权向上述行政、司法机关提供您在使用本网站时所储存的相关信息，包括但不限于您的注册信息等，或使用相关信息进行证据保全，包括但不限于公证、见证等；
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（2）新疆煤炭交易中心有权根据实际情况，在法律规定范围内自行决定单个用户在本网站及服务中数据的最长储存期限以及用户日志的储存期限，并在服务器上为其分配数据最大存储空间等。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>四、用户行为规范
      </span></p><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>1、您同意严格遵守法律法规规章规定，依法遵守以下义务：
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（1）不得制作、传输或发表以下违法信息资料：反对宪法所确定的基本原则，煽动抗拒、破坏宪法和法律法规实施的；危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的，煽动推翻社会主义制度的；损害国家荣誉和利益的；歪曲、丑化、亵渎、否定英雄烈士事迹和精神，侵害英雄烈士的姓名、肖像、名誉、荣誉的；宣扬或煽动实施恐怖主义、极端主义及其活动的；煽动民族仇恨、民族歧视、破坏民族团结的言论；破坏国家宗教政策，宣扬邪教和封建迷信的；散布谣言，扰乱经济秩序和社会秩序的；散布淫秽、色情、暴力或者教唆犯罪的；侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；法律、行政法规禁止的其他内容。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（2）防范和抵制制作、复制、发布含有下列内容的不良信息资料：标题严重夸张，发表内容与标题严重不符的；不当评述自然灾害、重大事故等灾难的；煽动人群歧视、地域歧视等的；宣扬低俗、庸俗、媚俗内容的；违反社会公德行为的；侵犯未成年人合法权益的；其他对网络生态造成不良影响的内容。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>2、本协议依据国家相关法律法规规章制定，您亦同意严格遵守以下义务：
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（1）从中国大陆向境外传输资料信息时必须符合中国大陆有关法规；
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（2）不得利用本网站从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（3）不得干扰本网站的正常运转，不得侵入本网站及国家计算机信息系统；
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（4）不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的、不文明的等信息资料；
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（5）不得教唆他人从事违法违规或本协议、平台规则所禁止的行为；
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（6）不得利用在本网站注册的账号进行牟利性经营活动；
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（7）不得发布任何侵犯他人个人信息、著作权、商标权等知识产权或合法权利的内容；
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>3、您须对自己在网上的言论和行为承担法律责任，您若在本网站上散布和传播反动、色情或其它违反国家法律的信息，本网站的系统记录有可能作为您违反法律的证据。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>五、本网站使用规范
      </span></p><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>1、除非法律允许或新疆煤炭交易中心书面许可，您使用本网站过程中不得从事下列行为：
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（1）删除本网站及其副本上关于著作权的信息；
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（2）对本网站进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本网站的源代码；
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（3）对新疆煤炭交易中心拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（4）对本网站或者本网站运行过程中释放到任何终端内存中的数据、网站运行过程中客户端与服务器端的交互数据，以及本网站运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经新疆煤炭交易中心授权的第三方工具/服务接入本网站和相关系统；
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（5）通过修改或伪造网站运行中的指令、数据，增加、删减、变动网站的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（6）通过非新疆煤炭交易中心开发、授权的第三方软件、插件、外挂、系统，登录或使用本网站及服务，或制作、发布、传播上述工具；
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（7）自行或者授权他人、第三方软件对本网站及其组件、模块、数据进行干扰。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>六、违约责任
      </span></p><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>1、如果新疆煤炭交易中心发现或收到他人举报投诉您违反本协议约定或存在任何恶意行为的，新疆煤炭交易中心有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁、注销等处罚，并公告处理结果。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>2、新疆煤炭交易中心有权依据合理判断对违反有关法律法规或本协议规定的行为采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>3、您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；新疆煤炭交易中心因此遭受损失的，您也应当一并赔偿。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>4、除非另有明确的书面说明, 新疆煤炭交易中心不对本网站的运营及其包含在本网站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>七、免责
      </span></p><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>1、本网站对服务之及时性、安全性、全面性和准确性不作担保。本网站从注册用户及用户处取得的各项信息数据，注册用户如需更改，可通过本网站提供的方式通知本网站更正，本网站无须对此数据信息的准确性承担任何责任和后果。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>2、您同意发生下列情形时，新疆煤炭交易中心将不承担任何责任，包括但不限于：
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（1）因不定期对提供网络服务的平台或相关的设备进行检修或者维护而导致的服务中断。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（2）因不可抗力、黑客攻击、病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵等原因而导致的用户信息泄漏、服务中断及其他损失。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>八、知识产权
      </span></p><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>1、本网站中包含的任何文字、图表、音频、视频等信息或材料均受著作权法、商标法和其他法律法规的保护，未经相关权利人书面同意，您不得以任何方式使用该信息或材料。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>2、本协议未授予您使用新疆煤炭交易中心任何商标、服务标记、标识、域名和其他显著品牌特征的权利。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>3、除本协议明确允许的以外，您不得以任何形式或任何方式对中国版权服务平台部分或全部内容进行修改、出租、租赁、出借、出售、分发、复制、创作衍生品或用于任何商业用途。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>九、法律管辖适用及其他
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>1、本协议的订立、执行和解释及争议的解决均应适用中国法律。如双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，应向新疆煤炭交易中心所在地有管辖权的人民法院提起诉讼。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>2、如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>3、本协议未明示授权的其他权利仍由新疆煤炭交易中心保留，您在行使这些权利时须另外取得新疆煤炭交易中心的书面许可。新疆煤炭交易中心如果未行使前述任何权利，并不构成对该权利的放弃。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>隐私政策
      </span></p><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>最近更新日期：2020年6月28日
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>本政策仅适用于新疆煤炭交易中心提供的所有产品和服务，包括新疆煤炭交易中心官网及微平台。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>本政策将帮助您了解以下内容：
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>一、我们如何收集和使用您的个人信息
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>二、我们如何使用Cookie
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>三、我们如何委托处理、共享、转让、公开披露您的个人信息
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>四、我们如何保护您的个人信息
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>五、您的权利
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>六、我们如何处理未成年人的个人信息
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>七、您的个人信息如何全球范围转移
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>八、本政策如何更新
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>九、如何联系我们
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>新疆煤炭交易中心（以下简称“我们”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠；我们致力于维持您对于我们的信任，恪守以下原则，保护您的个人信息；权责一致原则、目的明确原则、选择同意原则、最小必要原则、确保安全原则、主体参与原则、公开透明原则等。同时，新疆煤炭交易中心承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>请您在使用我们的官网、微平台及产品或服务前，仔细阅读并了解本《隐私政策》。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>一、我们如何收集和使用您的个人信息
      </span></p><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>您在使用新疆煤炭交易中心官网（以下简称“本网站”）各项业务的过程中，需要提供您的个人信息。这并非要求您必须向我们提供个人信息，但一些情况下，我们需要收集您的一些信息，帮助您成为我们的用户，向您提供良好的服务，为您优化用户体验，并提供安全保障。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>1、我们收集哪些您的个人信息
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（一）创建账号信息
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>当您注册本网站个人用户账号时，您需要提供您的用户名，手机号码，密码。当您注册本网站的机构用户账号时，您需要提供您的用户名，机构全称，邮箱，手机号码，密码。收集这些信息是为了帮您创建账号，如果您拒绝提供这些信息，将影响您的注册使用。
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>（二）实名认证
      </span></p><br><p style="margin: 0px; padding: 0px; width: 950px; transform: scale(1); transform-origin: left top; text-overflow: clip; white-space: normal; text-align: left; text-decoration: none; user-select: text;"><span>当您在本网站办理各类登记及查询业务时，我们需要您进行实名认证，个人账号的实名认证您需要提供您的国籍，所在省份及城市，真实姓名，性别，证件类型，证件号码，出生日期，证件有效期，上传证件照片，上传手持证件照片。机构账号的实名认证您需要提供您的国籍，所在省份及城市，机构类型，机构名称，成立日期，证件类型，证件号码，证件</span></p></div>
    </div>
    </div>
    <!-- <div class="flx_box">
      <el-button @click="tologin(1)">关闭</el-button>
      <el-button @click="tologin(2)">同意并继续</el-button>
    </div> -->
  </div>
</template>

<script>
export default {
  methods: {
    tologin(type) {
      this.$router.push({
        path: "/login",
        query: {
          key: type,
        },
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.el-button {
  margin: 0 100px;
}
.flx_box {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fu_box {
  height: calc(100% - 355px);
}
.protocol {
  overflow-y: auto;
  height: calc(100% - 60px);
}
.anniu {
  position: fixed;
  bottom: 0;
  width:950px;
  margin: 0 auto;
}
</style>